<template>
  <div id="explainer" class="link" @click="video = true">
    <!-- <div class="ex-image">
        <img src="@/assets/images/fg.png" style="width:33px;" alt="">
    </div> -->
    <div class="ex-text"> 
        <button-shimmer-exp></button-shimmer-exp>    
    </div>
    <b-overlay>
        <b-modal v-model="video"
      :header-bg-variant="($store.state.dark) ? 'dark' : 'light'"
      :header-text-variant="($store.state.dark) ? 'light' : 'dark'"
      :body-bg-variant="($store.state.dark) ? 'dark' : 'light'"
      :body-text-variant="($store.state.dark) ? 'light' : 'dark'"
      :footer-bg-variant="($store.state.dark) ? 'dark' : 'light'"
      :footer-text-variant="($store.state.dark) ? 'light' : 'dark'"
      hide-header hide-footer no-close-on-backdrop no-close-on-esc size="xl" centered>
      <button @click="video = false" class="ex-button btn btn-golds">&#x2715;</button>
      <iframe width="100%" height="555" src="https://www.youtube.com/embed/ykU1wAQqSbs" title="fuse.gold | gold X | explainer" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      
        </b-modal>
     </b-overlay>   
  </div>
</template>

<script>
import { BModal, BOverlay } from 'bootstrap-vue'
export default {
    components:{BModal, BOverlay,
        ButtonShimmerExp:() => import('@/components/ButtonShimmerExp.vue'),
    },
    data(){
        return {
            video: false
        }
    }
}
</script>

<style scoped>
.ex-button{
    float: right;
    position: absolute;
    right: 1px
}
#explainer{
    /* position: fixed; */
    z-index:999;
    right:-6px;
    /* border-radius:21px 5px 0px 18px; */
    height:43px;
    top:45vh;
    width:220px;
    /* background:#FFFF; */
    /* border:5px solid #b99653; */
}
.ex-image{
width:40px;
display:inline-block;
}
.ex-text{
    display:inline-block;
    font-size:13px;
}
</style>